.CodeMirror {
  padding: 20px;
  min-height: 100px;
  min-width: 100px;
  height: fit-content;
  display: inline-block;
  direction: ltr;
  font-weight: 300;
  line-height: 1.6;
  font-family: 'IBM Plex Mono', monospace;
  letter-spacing: 0.425px;
}

.CodeMirror-vscrollbar {
  display: none !important;
}

.CodeMirror-hscrollbar {
  display: none !important;
}

.CodeMirror-scroll {
  overflow: auto !important;
}
